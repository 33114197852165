import React, { Component } from "react";
import RecordUpload from "./RecordUpload";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

class App extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/upload/:meetingId/:lang"
                            element={<RecordUpload {...this.props} />}
                        />
                    </Routes>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
