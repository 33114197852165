import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { BsTrash, BsFillFileEarmarkPlayFill } from "react-icons/bs";
import AWS from "aws-sdk";
import axios from "axios";
import withRouter from "./withRouter";
import "./style.css";

const BUCKET_NAME = "nearvideorecordings";
const S3_PATH = "videoconference";
const BUCKET_REGION = "ap-south-1";
const ACCESS_KEY_ID = "AKIA2D3DBFI4EN4TZNET";
const SECRET_ACCESS_KEY = "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";
const API_URL = "https://vc.nearconferences.com/api/";

AWS.config.update({
    region: BUCKET_REGION,
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();

class RecordUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filesInfo: [],
            isUploading: false,
            isUploaded: false,
        };
        this.fileRef = React.createRef();
        this.handleAllowUpload = this.handleAllowUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleRemoveFile = this.handleRemoveFile.bind(this);
    }

    handleAllowUpload = () => {
        this.fileRef.current.click();
    };

    handleChange = (e) => {
        const { filesInfo } = this.state;
        const files = e.target.files;
        let fileList = [];
        for (let i = 0; i < files.length; i++) {
            fileList.push(files[i]);
        }

        this.setState({ filesInfo: filesInfo.concat(fileList) });
    };

    handleUpload = (e) => {
        const { params } = this.props;
        console.log("params.meetingId :>> ", params.meetingId);
        let that = this;
        this.setState({ isUploading: true });
        const { filesInfo } = this.state;
        for (let i = 0; i < filesInfo.length; i++) {
            var uploadParams = {
                Bucket: BUCKET_NAME,
                Key: `${S3_PATH}/${params.meetingId}/recordings/recording${
                    i + 1
                }.webm`,
                Body: filesInfo[i],
                ContentType: "video/webm",
                ACL: "public-read",
            };
            var s3upload = s3.upload(uploadParams).promise();
            s3upload
                .then(function (data) {
                    if (i === filesInfo.length - 1) {
                        that.setState({ isUploaded: true, isUploading: false });
                        axios({
                            method: "GET",
                            url: `${API_URL}meetings/processRecording/${params.meetingId}`,
                            responseType: "json",
                        })
                            .then((response) => {
                                // console.log(`process the video`)
                            })
                            .catch((error) => {
                                // console.log(`Could not process the video`)
                            });
                    }
                })
                .catch(function (err) {
                    that.setState({ isUploaded: false, isUploading: false });
                    console.log("err :>> ", err);
                });
        }
    };

    handleRemoveFile = (index) => {
        const currentFiles = [...this.state.filesInfo];
        currentFiles.splice(index, 1);
        this.setState({ filesInfo: currentFiles });
        if (currentFiles.length === 0) {
            this.fileRef.current.value = "";
        }
    };

    render() {
        const { params } = this.props;
        const { filesInfo, isUploaded, isUploading } = this.state;
        return (
            <div className="upload-container text-center p-3">
                <div
                    className={
                        params.lang === "ar"
                            ? "upload-ask-prompt rtl-main"
                            : "upload-ask-prompt"
                    }
                >
                    {isUploading && (
                        <div className="webmuploading">
                            <img
                                className="loaderImage"
                                src="/loader.svg"
                                alt="loading"
                            />
                            <p>
                                {params.lang === "ar"
                                    ? "...جاري التحميل"
                                    : "uploading..."}
                            </p>
                        </div>
                    )}
                    {isUploaded && (
                        <p className="text-success">
                            {params.lang === "ar"
                                ? "تم تحميل مقاطع الفيديو بنجاح"
                                : "Videos uploaded successfully"}
                        </p>
                    )}
                    {!isUploaded && filesInfo.length > 0 && (
                        <ul className="selected-file-list">
                            {filesInfo &&
                                filesInfo.map((items, index) => {
                                    return (
                                        <li key={index}>
                                            <span>
                                                <BsFillFileEarmarkPlayFill />
                                            </span>
                                            <span>{items.name}</span>
                                            <span
                                                onClick={() =>
                                                    this.handleRemoveFile(index)
                                                }
                                            >
                                                <BsTrash />
                                            </span>
                                        </li>
                                    );
                                })}
                        </ul>
                    )}

                    <div className="prompt-action">
                        <input
                            type="file"
                            onChange={this.handleChange}
                            ref={this.fileRef}
                            style={{ display: "none" }}
                            accept="video/webm"
                            multiple
                        />
                        {filesInfo.length > 0 && !isUploaded ? (
                            <>
                                <Button
                                    variant="primary"
                                    onClick={() => this.handleAllowUpload()}
                                >
                                    {params.lang === "ar"
                                        ? "أضف مقاطع فيديو"
                                        : "Add Videos"}
                                </Button>
                                <Button
                                    className="ms-2"
                                    variant="success"
                                    onClick={() => this.handleUpload()}
                                >
                                    {params.lang === "ar" ? "حفظ" : "Save"}
                                </Button>
                            </>
                        ) : (
                            <Button
                                variant="primary"
                                onClick={() => this.handleAllowUpload()}
                            >
                                {params.lang === "ar"
                                    ? "تحميل أشرطة الفيديو"
                                    : "Upload Videos"}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RecordUpload);
